<script>
export default {
    data() {
        return {
            weekFrequencies: [
                {
                    id: 'ANY_DAY_OF_WEEK',
                    label: this.getI18n('FREQUENCIES', 'ANY_DAY_OF_WEEK'),
                },
                {
                    id: 'DAY_OF_WEEK_MONDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_MONDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_TUESDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_TUESDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_WEDNESDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_WEDNESDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_THURSDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_THURSDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_FRIDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_FRIDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_SATURDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_SATURDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_SUNDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_SUNDAY'),
                },
            ],
            workScheduleFrequencies: [
                {
                    id: 'ANY_DAY_OF_WEEK',
                    label: this.getI18n('FREQUENCIES', 'ANY_DAY_OF_WEEK'),
                    isDefaultExpanded: true,
                    children: [
                        {
                            id: 'DAY_OF_WEEK_MONDAY',
                            label: this.getI18n(
                                'FREQUENCIES',
                                'DAY_OF_WEEK_MONDAY'
                            ),
                        },
                        {
                            id: 'DAY_OF_WEEK_TUESDAY',
                            label: this.getI18n(
                                'FREQUENCIES',
                                'DAY_OF_WEEK_TUESDAY'
                            ),
                        },
                        {
                            id: 'DAY_OF_WEEK_WEDNESDAY',
                            label: this.getI18n(
                                'FREQUENCIES',
                                'DAY_OF_WEEK_WEDNESDAY'
                            ),
                        },
                        {
                            id: 'DAY_OF_WEEK_THURSDAY',
                            label: this.getI18n(
                                'FREQUENCIES',
                                'DAY_OF_WEEK_THURSDAY'
                            ),
                        },
                        {
                            id: 'DAY_OF_WEEK_FRIDAY',
                            label: this.getI18n(
                                'FREQUENCIES',
                                'DAY_OF_WEEK_FRIDAY'
                            ),
                        },
                        {
                            id: 'DAY_OF_WEEK_SATURDAY',
                            label: this.getI18n(
                                'FREQUENCIES',
                                'DAY_OF_WEEK_SATURDAY'
                            ),
                        },
                        {
                            id: 'DAY_OF_WEEK_SUNDAY',
                            label: this.getI18n(
                                'FREQUENCIES',
                                'DAY_OF_WEEK_SUNDAY'
                            ),
                        },
                    ],
                },
            ],
        }
    },
}
</script>
