<script>
import timezones from '@src/assets/resources/timezones'
import costs from '@assets/resources/costs'
import platforms from '@assets/resources/platforms'
import workScheduleFrequencies from '@src/mixins/workScheduleFrequencies.vue'
import moment from 'moment'

export default {
  mixins: [workScheduleFrequencies],
  methods: {
    formatCheckInfo(date, distance) {
        if (date) {
            const distanceFormatted = Number.isNaN(Math.round(distance)) ? 0 : Math.round(distance)
            return `${this.formatDateTime(date)}${distanceFormatted > 0 ? `<br>${distanceFormatted}m`: ''}`
        }
        return this.formatDateTime(date)
    },

    formartJustificationStatus(status) {
        if (status) {
            return `<b-badge variant="success">${this.getI18n('JUSTIFICATION', 'FIELDS.approved')}</b-badge>`
        }
        return `<b-badge variant="warning">${this.getI18n('JUSTIFICATION', 'FIELDS.pending')}</b-badge>`
    },

    formartFile(file) {
        if (file && file.url) {
            return `<a href="${file.url}" target="_blank">
                        <i class="fe-camera"/>
                    </a>`
        }

        return '<i v-else class="fe-camera-off" />'
    },

    formatCostType(id) {
        const costFound = costs.find((cost) => cost.id === id)

        if (costFound) {
            return costFound.label
        }

        return id
    },

    formatTimezone(timezoneName) {
      const timezoneFound = timezones.find(
        (timezone) => timezone.id === timezoneName
      )

      if (timezoneFound) {
        return timezoneFound.timezone
      }

      return timezoneName
    },

    formatDate(date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      }
      return '<i class="fe-slash" aria-hidden="true"></i>'
    },

    formatDateShort(date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD').format('DD/MM/YY')
      }
      return ''
    },

    formatDateTime(date, breakLine = true) {
      if (date) {
        const arrFullDate = date.split(' ')
        const arrFormattedDate = arrFullDate[0].split('-')
        const arrFormattedTime = arrFullDate[1].split(':')
        return (
          `${breakLine ? '<span>' : ''}` +
          arrFormattedDate[2] +
          '/' +
          arrFormattedDate[1] +
          '/' +
          arrFormattedDate[0][2] +
          '' +
          arrFormattedDate[0][3] +
          `${breakLine ? '<br>' : ' '}` +
          arrFormattedTime[0] +
          ':' +
          arrFormattedTime[1] +
          ':' +
          arrFormattedTime[2] +
          `${breakLine ? '<span>' : ''}`
        )
      }
      return breakLine ? '<i class="fe-slash" aria-hidden="true"></i>' : ''
    },

    formatHour(value) {
      return moment(value, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
    },

    formatKilobyte(value) {
        if (!value) {
            return ''
        }

        return `${Math.ceil(value / 1024)} KB`
    },

    formatPrice(price) {
      if (price) {
        return `${this.getI18n('CURRENCY.prefix')} ${parseFloat(price.replace(',', '.')).toFixed(2)}`
      }

      return ''
    },

    formatLatLong(arrLatLong) {
      if (arrLatLong) {
        return (
          '<span>(' + arrLatLong[0] + ',' + arrLatLong[1] + ')</span>'
        )
      }
      return ''
    },

    formatLatLongWithDistance(arrLatLong, distance) {
      let result = ''
      if (arrLatLong) {
        let distanceKm = (distance / 1000).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        if (Number.isNaN(distance / 1000)) {
          distanceKm = 0;
        }
        result =
          '<div class="text-center">' +
          '<span>(' +
          arrLatLong[0] +
          ',' +
          arrLatLong[1] +
          ')</span><br>' +
          '<span class="distanceInfo">' +
          distanceKm +
          ' km </span>' +
          '</div>'
      }
      return result
    },

    formatPlaceLink([lat, long]) {
      if (typeof lat === 'number' && typeof long === 'number')
        return `<a href="http://maps.google.com/?q=${lat},${long}" target="_blank"><i class="fe-map" aria-hidden="true"></i></a>`
      return '<i class="fe-slash" aria-hidden="true"></i>'
    },

    formatMapLink2({
                     location_in: [latIn, longIn],
                     location_out: [latOut, longOut],
                   }) {
      if (
        typeof latIn === 'number' &&
        typeof longIn === 'number' &&
        typeof latOut === 'number' &&
        typeof longOut === 'number'
      ) {
        return `
                <a href="https://www.google.com.br/maps/dir/${latIn},${longIn}/${latOut},${longOut}/@${latOut},${longOut},5z" target="_blank">
                    <i class="fe-map" aria-hidden="true"></i>
                </a>
                `
      }
      return '<i class="fe-slash" aria-hidden="true"></i>'
    },

    formatMapLink(rowObj) {
        if (rowObj.location_in && rowObj.location_out) {
            let link = ''
            if (rowObj.location_out[0] !== 0 && rowObj.location_out[1] !== 0) {
                link = `https://google.com/maps/dir/${rowObj.location_in[0]},${rowObj.location_in[1]}/${rowObj.location_out[0]},${rowObj.location_out[1]}/@${rowObj.location_out[0]},${rowObj.location_out[1]},12z`
            } else {
                link = `https://google.com/maps/place/${rowObj.location_in[0]},${rowObj.location_in[1]}`
            }
            return `<a href="${link}" target="_blank"><i class="fe-map" aria-hidden="true"></i></a>`
        }
        return '<i class="fe-slash" aria-hidden="true"></i>'
    },

    formatMinutes(minutes) {
        if (minutes) {
            return `${minutes} ${this.$options.filters.firstToLower(
                this.getI18nModified({
                    prefix: 'COMMON',
                    suffix: 'minutes',
                    modifier: minutes,
                })
            )}`
        }

        return ''
    },

    formatHours(hours) {
        if (hours) {
            return `${hours} ${this.$options.filters.firstToLower(
                this.getI18nModified({
                    prefix: 'COMMON',
                    suffix: 'hours',
                    modifier: hours,
                })
            )}`
        }

        return ''
    },

    booleanIcons(value) {
      const yes = this.getI18n('COMMON', 'yes') || 'yes'
      const no = this.getI18n('COMMON', 'no') || 'no'
      return value ? yes : no
    },

    formatPercentage(value) {
        if (!value) {
            return '0%'
        }

        return `${value.toFixed(2)}%`
    },

    formatPercentageInteger(value) {
        if (!value) {
            return '0%'
        }

        return `${Math.round(value)}%`
    },

    formatFrequency(value) {
        let frequencyFormatted = ''
        if (Array.isArray(value)) {
            value.forEach((element) => {
                const frequency = this.weekFrequencies.find(
                    (frequency) => element === frequency.id
                )
                const separator = frequencyFormatted.length > 0 ? ', ' : ''
                frequencyFormatted = `${frequencyFormatted}${separator}${frequency.label}`
            })
        }
        return frequencyFormatted
    },

    formatWeek(value) {
        if (!value) {
            return ''
        }
        else if (value === 1) {
            return this.getI18n('DASHBOARDS', 'TYPES.first_week')
        }
        else if (value === 2) {
            return this.getI18n('DASHBOARDS', 'TYPES.second_week')
        }
        else if (value === 3) {
            return this.getI18n('DASHBOARDS', 'TYPES.third_week')
        }
        else if (value === 4) {
            return this.getI18n('DASHBOARDS', 'TYPES.fourth_week')
        }
        else if (value === 5) {
            return this.getI18n('DASHBOARDS', 'TYPES.fifth_week')
        }

        return ''
    },

    padLeft(value, count = 3) {
        if (!value || value === undefined || value === null) {
            return '000';
        }

        return String(value).padStart(count, '0');
    },

    formatPlatform(platform, version = '') {
        if (!platform) {
            return ''
        }

        const platformText = platforms.find((p) => p.value === platform)?.text
        return `${platformText} ${version}`
    }
  },
}
</script>
<style>
.batteryPercentage span {
  font-size: 12px;
  font-weight: bold;
}

.distanceInfo {
  font-size: 12px;
  font-weight: bold;
}
</style>
